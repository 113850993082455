<template>
  <div>
    <!-- Yükleme Spinner -->
    <div v-if="loading" class="text-center">
      <strong>Talep Edilen Veriler Yükleniyor...</strong>
      <br><br>
      <div class="spinner-border ms-auto text-success" role="status" aria-hidden="true"></div>
    </div>

    <!-- Veri Tablosu -->
    <div v-else class="row">
      <div v-if="inventoryData && inventoryData.length > 0" class="card">
        <!-- Başlık -->
        <div class="row card-header mb-3">
          <div class="col">
            <h3 class="card-title">Tüm Stoklar</h3>
          </div>
        </div>

        <!-- Ürün Arama -->
        <div class="row mb-3">
          <div class="col">
            <div class="input-group">
              <input
                v-model="search"
                type="search"
                class="form-control"
                placeholder="Ürün Araması Yapınız..."
              />
              <span class="input-group-text">{{ searchFilter.length }}</span>
            </div>
          </div>
        </div>

        <!-- Tablo -->
        <div class="row">
          <div class="col-md-12">
            <table class="table table-striped table-hover table-bordered">
              <thead class="text-white bg-danger thead">
                <!-- Toplam Değerler -->
                <tr style="background-color: white; color: black;">
                  <th v-for="col in columns" :key="'total-' + col.name">
                    <!-- itemCode ve itemName için toplam göstermeyin -->
                    <span v-if="col.name !== 'itemCode' && col.name !== 'itemName'">
                      {{ totals[col.name]?.toLocaleString('tr-TR') || '' }}
                    </span>
                  </th>
                </tr>
                <!-- Başlıklar -->
                <tr>
                  <th v-for="col in columns" :key="col.name" @click="selectColumn(col.name)">
                    {{ getColumnLabel(col) }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="data in searchFilter" :key="data.itemCode" style="font-weight: bold;">
                  <td v-for="col in columns" :key="col.name">
                    <!-- {{ data[col.name]?.toLocaleString('tr-TR') || '' }} -->
                    {{ data[col.name] || 0 }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <!-- Veri Bulunamadı Mesajı -->
      <div v-else>
        <p class="text-center">Gösterilecek veri bulunamadı.</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AllStocks",
  data() {
    return {
      inventoryData: null,
      selectedColumn: "itemCode",
      sortType: "DESC",
      search: "",
      loading: true,
      columns: [
        { name: "itemCode", label: "Ürün Kodu" },
        { name: "itemName", label: "Ürün Adı" },
        { name: "totalBaseStock", label: "Merkez Stok" },
        { name: "machineStock", label: "Makine Stok" },
        { name: "totalStock", label: "Toplam Stok" },
        { name: "totalOrderWS", label: "Toptan Sipariş" },
        { name: "totalOrderS", label: "Mağaza Sipariş" },
        { name: "totalOnlineOrder", label: "Online Sipariş" },
        { name: "totalOrder", label: "Toplam Sipariş" },
        { name: "availableStock", label: "Kull. Stok" },
        { name: "activeInventoryNeed", label: "Aktif İhtiyaç" },
        { name: "totalSales", label: "Aylık Satış" },
        { name: "monthlyInventoryNeed", label: "Aylık İhtiyaç" },
      ],
      totals: {},
    };
  },
  computed: {
    searchFilter() {
      this.clearTotals();
      return this.inventoryData.filter((data) => {
        const searchLower = this.search.toLowerCase();
        if (
          data.itemName.toLowerCase().includes(searchLower) ||
          data.itemCode.toLowerCase().includes(searchLower)
        ) {
          this.updateTotals(data);
          return true;
        }
        return false;
      });
    },
  },
  mounted() {
    this.getAllStocks();
  },
  methods: {
    clearTotals() {
      this.totals = {};
      this.columns.forEach((col) => {
        this.totals[col.name] = 0;
      });
    },
    updateTotals(data) {
      this.columns.forEach((col) => {
        if(col.name != 'itemCode' && col.name != 'itemName') {
          this.totals[col.name] += data[col.name] || 0;
        }
      });
    },
    getAllStocks() {
      this.axios
        .get(`${this.baseUrl}/report/inventoryNeed`, this.$store.getters.authHeader)
        .then((response) => {
          this.inventoryData = response.data;
          this.handleData();
          this.loading = false;
        })
        .catch((e) => {
          console.error(e);
        });
    },
    handleData() {
      this.inventoryData.forEach((element) => {
        element.totalBaseStock = element.prsStock + element.copperStock + element.kimyaStock;
        element.totalStock = element.totalBaseStock + element.machineStock;
        element.totalOrderWS = element.prsOrderWS + element.kimyaOrderWS;
        element.totalOrderS = element.prsOrderS + element.prsDispOrderS;
        element.totalOnlineOrder = element.prsOnlineOrder + element.copperOnlineOrder;
        element.totalOrder = element.totalOrderWS + element.totalOrderS + element.totalOnlineOrder;
        element.availableStock = element.totalStock - element.totalOrder;
        element.activeInventoryNeed = element.availableStock >= 0 ? 0 : element.availableStock * -1;
        element.totalSales = element.prsOnlineSales + element.copperOnlineSales + element.prsStoreSales;
        const temp = element.availableStock - element.totalSales;
        element.monthlyInventoryNeed = temp >= 0 ? 0 : temp * -1;
      });
    },
    selectColumn(columnName) {
      if (columnName === this.selectedColumn) {
        this.sortType = this.sortType === "DESC" ? "ASC" : "DESC";
      } else {
        this.selectedColumn = columnName;
        this.sortType = "DESC";
      }
      this.sortData();
    },
    sortData() {
      const multiplier = this.sortType === "DESC" ? 1 : -1;
      this.inventoryData.sort(
        (a, b) => (a[this.selectedColumn] - b[this.selectedColumn]) * multiplier
      );
    },
    getColumnLabel(col) {
      return this.selectedColumn === col.name
        ? `${col.label} ${
            this.sortType === "DESC" ? "↓" : "↑"
          }`
        : col.label;
    },
  },
};
</script>

<style>
.thead {
  position: sticky;
  top: 0;
}
.table-container {
  overflow: auto;
  max-height: 70vh;
}
.text-center {
  text-align: center;
  margin-top: 50px;
}
</style>